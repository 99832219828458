<template>
  <div>
    <div class="p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page Not Found
        </h2>
        <p class="mb-2">
           The requested URL was not found on this server.
        </p>
        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
}

</script>
